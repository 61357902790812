import React from 'react';

import tw, { styled } from 'twin.macro';

const Wrapper = styled.dl(() => [
	tw`mt-12
	space-y-10
	sm:space-y-0
	sm:grid
	sm:grid-cols-2
	sm:col-gap-6
	sm:row-gap-12
	lg:grid-cols-3
	lg:col-gap-8`,
]);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Features: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<div className="flex space-x-3">
				<svg
					className="flex-shrink-0 h-6 w-6 text-green-500"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
				</svg>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Only a small one-time fee
					</dt>
					<dd className="flex space-x-3 lg:py-0 lg:pb-4">
						<span className="text-base leading-6 text-gray-500">
							Our price is for setting up your own VPN server on your own Virtual
							Machine hosted at one of our partners
						</span>
					</dd>
				</div>
			</div>
			<div className="flex space-x-3">
				<svg
					className="flex-shrink-0 h-6 w-6 text-green-500"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
				</svg>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Privacy first approach
					</dt>
					<dd className="flex space-x-3">
						<span className="text-base leading-6 text-gray-500">
							When we say no logs we really mean it. No other provider puts everything
							on the table like we do
						</span>
					</dd>
				</div>
			</div>
			<div className="flex space-x-3">
				<svg
					className="flex-shrink-0 h-6 w-6 text-green-500"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
				</svg>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Open source software
					</dt>
					<dd className="flex space-x-3">
						<span className="text-base leading-6 text-gray-500">
							We install only open source software which you can inspect - tried and
							tested by many and usually safer
						</span>
					</dd>
				</div>
			</div>
			<div className="flex space-x-3">
				<svg
					className="flex-shrink-0 h-6 w-6 text-green-500"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
				</svg>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Installed on your own server
					</dt>
					<dd className="flex space-x-3">
						<span className="text-base leading-6 text-gray-500">
							We connect with your account and spawn a new VM to install the VPN server on.
							No technical skills required
						</span>
					</dd>
				</div>
			</div>
			<div className="flex space-x-3">
				<svg
					className="flex-shrink-0 h-6 w-6 text-green-500"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
				</svg>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Up and running in minutes
					</dt>
					<dd className="flex space-x-3 lg:py-0 lg:pb-4">
						<span className="text-base leading-6 text-gray-500">
							Most installs take between 5 and 7 minutes and that includes creating
							the VM and installing the VPN software
						</span>
					</dd>
				</div>
			</div>
			<div className="flex space-x-3">
				<svg
					className="flex-shrink-0 h-6 w-6 text-green-500"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
				</svg>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Use on all your devices
					</dt>
					<dd className="flex space-x-3">
						<span className="text-base leading-6 text-gray-500">
							Connect to your VPN server from any device you like - our tutorials
							will guide you at every step of the way
						</span>
					</dd>
				</div>
			</div>
		</Wrapper>
	);
};

export default Features;
